import { useState, useEffect } from 'react'
import { constants as cons } from '../../Constants/constants'
import { useSelector } from 'react-redux'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import InputAdornment from '@mui/material/InputAdornment'
import { makeStyles } from '@mui/styles'
import { getString, isObj } from '../../helper/util'

// import MTextField from '@mui/material/TextField'
import moment from 'moment'

import { isBlank } from '../../helper/util'
import { TextField } from '@mui/material'

import variables from '../../styles/globalVariables.scss'
const { blackColor } = variables

// const {
//   className,
//   clear, // Clear Button
//   copyName, // the name of the field which are set as auto translate
//   disabledOnClickInputField, // Disable The On Click Button
//   disabled, // Is Field Disabled
//   disabledStyle, // Is The Field In Disable Style
//   endAdornment, // End Adornment
//   endText, // Not really needed for now, endAdornment can cover
//   FormHelperTextProps, // Not really needed for now
//   helperText, // Not really needed for now
//   helperTextIcon, // Not really needed for now
//   disabledEndAdornmentIconButton, // Is End Adornment Icon Button Disabled
//   inputList, // a extra storage of the whole edit/create list for directly change of the tc/sc auto translate
//   inputMode, // MUI InputProps inputMode
//   label,
//   max, // MUI InputProps - Max
//   min, // MUI InputProps - Min
//   multiple, // MUI SelectProps - Multiple
//   multiline,
//   name,
//   onClickEndAdornmentIconButton, // End Adornment On Click Function
//   endAdornmentIconButton, // End Adornment On Click Icon
//   pattern, // MUI InputProps - Pattern
//   placeholder,
//   required, // Is Required
//   setInputList, // a extra storage of the whole edit/create list for directly change of the tc/sc auto translate
//   size, // MUI Size
//   startAdornment,
//   step, // MUI InputProps - Step
//   translateName, // the name of the field which are set as auto translate
//   type, // constants.type
//   value,
//   variant,
// } = inputSetting;

const disableTextStyle = makeStyles(() => ({
    disabledInput: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: `${blackColor}`,
            marginRight: 5,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(18, 18, 18, 0.54)',
            marginRight: 5,
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'none',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            '-webkit-text-fill-color': `${blackColor}`,
        },
    },
}))

const normalTextStyle = makeStyles(() => ({
    label: {
        '& .MuiFormLabel-root': {
            color: `${blackColor}`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${blackColor}`,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(18, 18, 18, 0.54)',
        },
    },
}))

const onClickTextStyle = makeStyles(() => ({
    label: {
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `${blackColor}`,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: `${blackColor}`,
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${blackColor}`,
            },
        },
        '& .MuiOutlinedInput-input': {
            cursor: 'pointer',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            '-webkit-text-fill-color': `${blackColor}`,
        },
    },
}))

export const TempTextInput = ({ inputSetting: p, index, handleChange, dataList, label }) => {
    const disableTextStyleClasses = disableTextStyle()
    const normalTextStyleClasses = normalTextStyle()
    const onClickTextStyleClasses = onClickTextStyle()
    const lang = useSelector((state) => state.lang.ui.lang)
    const theme = useSelector((state) => state.theme.ui.theme)
    const [localValue, setLocalValue] = useState('')
    const [textChanged, setTextChanged] = useState(false)

    useEffect(() => {
        if (label !== 'filter') {
            if (p.inputList) {
                p.inputList[p.name] = p.value
                setLocalValue(p.value)

                if (p.translateName) {
                    p.translateName.map((el) => {
                        p.inputList[el] = p.value ? sify(p.value) : p.value
                        return el
                    })
                }

                if (p.copyName) {
                    p.copyName.map((el) => {
                        p.inputList[el] = p.value
                        return el
                    })
                }
                p.setInputList(p.inputList)
            } else if (!p.inputList && localValue !== p.value) {
                setLocalValue(p.value)
            }

            // if (timeout) {
            //   clearTimeout(timeout)
            // }
        }
    }, [p.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const overlayDiv = document.getElementById('webpack-dev-server-client-overlay-div')
                const overlay = document.getElementById('webpack-dev-server-client-overlay')
                if (overlay) {
                    overlay.setAttribute('style', 'display: none')
                }
                if (overlayDiv) {
                    overlayDiv.setAttribute('style', 'display: none')
                }
            }
        })
    }, [])

    if (!p.className) {
        p.className = 'edit-create-input'
    }

    if (p.variant === cons.STYLE.VARIANT.STANDARD) {
        p.className += ' ' + disableTextStyleClasses.disabledInput
    }

    if (p.onClickEndAdornmentIconButton && p.disabled && !p.disabledStyle) {
        p.className += ' ' + onClickTextStyleClasses.label
    } else {
        p.className += ' ' + normalTextStyleClasses.label
    }

    if (p.helperTextIcon === true || p.helperTextIcon === null || p.helperTextIcon === undefined) {
        p.helperTextIcon = <CancelIcon className='error-message-icon' fontSize={cons.SIZE.SMALL} />
    }

    if (p.type === cons.TYPE.DATETIME_LOCAL && !p.max) {
        p.max = '9999-12-31T23:59:59'
    }

    // useEffect(() => {
    //   console.log('p.value', p.value)
    // }, [p.value])

    // useEffect(() => {
    //   console.log('localValue', localValue)
    // }, [localValue])

    const getValue = () => {
        if (label === 'filter') {
            if (isBlank(p.value)) {
                return ''
            } else if (p.type === cons.TYPE.DATETIME_LOCAL) {
                return moment(p.value).format('YYYY-MM-DDTHH:mm:ss')
            } else {
                return p.value
            }
        } else {
            if (isBlank(p.inputList ? p.inputList[p.name] : localValue)) {
                return ''
            } else if (p.type === cons.TYPE.DATETIME_LOCAL) {
                const val = p.inputList ? p.inputList[p.name] : localValue
                return moment(val).format('YYYY-MM-DDTHH:mm:ss')
            } else {
                return p.inputList ? p.inputList[p.name] : localValue
            }
        }
    }

    return (
        <TextField
            size={p.size || cons.SIZE.SMALL}
            className={p.className}
            variant={p.variant || cons.STYLE.VARIANT.OUTLINED}
            label={
                isObj(p.label)
                    ? getString(lang, p.label.resource || '', p.label.key || '', theme)
                    : p.label
            }
            value={getValue()}
            onChange={
                handleChange &&
                ((e) => {
                    setTextChanged(true)
                    let newValue = e.target.value
                    if (label === 'filter') {
                        handleChange(p.name, newValue, index, dataList)
                    } else {
                        if (p.inputList) {
                            p.inputList[p.name] = newValue

                            if (p.translateName) {
                                p.translateName.map((el) => {
                                    p.inputList[el] = newValue ? sify(newValue) : newValue
                                    return el
                                })
                            }

                            if (p.copyName) {
                                p.copyName.map((el) => {
                                    p.inputList[el] = newValue
                                    return el
                                })
                            }

                            p.setInputList(_.cloneDeep(p.inputList))
                        } else {
                            setLocalValue(newValue)
                        }

                        // if (timeout) {
                        //   clearTimeout(timeout)
                        // }

                        // const newTimeout = setTimeout(() => {
                        //   handleChange(p.name, newValue, index, dataList)
                        //   setTextChanged(false)
                        // }, 500)

                        // timeout = newTimeout
                    }
                })
            }
            onBlur={
                handleChange &&
                (() => {
                    if (label !== 'filter' && textChanged) {
                        handleChange(
                            p.name,
                            p.inputList ? p.inputList[p.name] : localValue,
                            index,
                            dataList
                        )
                        setTextChanged(false)
                        // if (timeout) {
                        //   clearTimeout(timeout)
                        // }
                    }
                })
            }
            helperText={
                p.helperText &&
                p.helperText.length > 0 && (
                    <span className='error-message'>
                        {p.helperTextIcon}
                        {p.helperText}
                    </span>
                )
            }
            // FormHelperTextProps={errorStyle}
            disabled={p.disabled || false}
            multiline={p.multiline || false}
            required={p.required}
            type={p.type}
            InputLabelProps={{ shrink: true }}
            placeholder={p.placeholder}
            onClick={p.disabledOnClickInputField && null}
            SelectProps={{
                multiple: p.multiple,
            }}
            InputProps={{
                shrink: 'true',
                startAdornment: p.startAdornment,
                endAdornment: (
                    <>
                        {p.clear &&
                            !isBlank(p.value) &&
                            p.onClickEndAdornmentIconButton &&
                            !p.endAdornmentIconButton && (
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleChange(p.name, '', index)
                                        }}
                                        color={cons.STYLE.PRIMARY}
                                        size={cons.SIZE.SMALL}
                                    >
                                        <ClearIcon fontSize={cons.SIZE.SMALL} />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        {p.endAdornment && <InputAdornment>{p.endAdornment}</InputAdornment>}
                        {p.onClickEndAdornmentIconButton && (
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={p.onClickEndAdornmentIconButton}
                                    color={cons.STYLE.PRIMARY}
                                    sx={p.disabled && p.disabledStyle ? { color: 'grey' } : {}}
                                    size={cons.SIZE.SMALL}
                                    disabled={p.disabledEndAdornmentIconButton}
                                >
                                    {p.endAdornmentIconButton || (
                                        <EditIcon fontSize={cons.SIZE.SMALL} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        )}
                        {p.endAdornmentIconButtonList &&
                            p.endAdornmentIconButtonList.length > 0 &&
                            p.endAdornmentIconButtonList.map((endAdornmentIconButton) => (
                                <InputAdornment position='end'>
                                    <IconButton
                                        color={cons.STYLE.PRIMARY}
                                        disabled={p.disabledEndAdornmentIconButton}
                                    >
                                        {endAdornmentIconButton}
                                    </IconButton>
                                </InputAdornment>
                            ))}
                        {p.endText}
                    </>
                ),
                inputProps: {
                    inputMode: p.inputMode,
                    pattern: p.pattern,
                    min: p.min,
                    max: p.max,
                    step: p.step,
                    readOnly: p.readOnly,
                },
            }}
            // inputadornmentprops={{
            //   position: 'start',
            // }}
        ></TextField>
    )
}
